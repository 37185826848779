import qs from 'qs'
import Bugsnag from '@bugsnag/js'

let axios
let url
let publicToken

export default {
  setAxios ($axios) {
    axios = $axios
  },
  setUrl (paramUrl) {
    url = paramUrl
  },
  setPublicToken (token) {
    publicToken = token
  },
  async getToken (username, password) {
    try {
      const response = await axios.post(url + '/login_check', qs.stringify({ username, password }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      return response.data.token
    } catch (e) {
      Bugsnag.notify(e)
      throw e
    }
  },
  async sendForgotPassword (mail, service) {
    try {
      await axios.post(url + '/global/send_forgot_password_form', qs.stringify({ mail, service }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    } catch (e) {
      Bugsnag.notify(e)
      throw e
    }
  },
  async updatePasswordForIdWithToken (id, token, newPassword) {
    try {
      await axios.post(url + '/internal/update_password_for_id_with_token', qs.stringify({ id, token, newPassword }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    } catch (e) {
      Bugsnag.notify(e)
      throw e
    }
  },
  async updatePassword (newPassword) {
    try {
      await axios.post(url + '/update_password', qs.stringify({ newPassword }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    } catch (e) {
      Bugsnag.notify(e)
      throw e
    }
  },
  async getActiveSubscriptionsInfo () {
    try {
      const response = await axios.get(url + '/get_active_subscriptions_info')
      return response.data
    } catch (e) {
      Bugsnag.notify(e)
      throw e
    }
  },
  async getActiveDemosInfo () {
    try {
      const response = await axios.get(url + '/get_active_demos_info')
      return response.data
    } catch (e) {
      Bugsnag.notify(e)
      throw e
    }
  },
  async getServicesInfos () {
    try {
      const response = await axios.get(url + '/internal/get_info_services/uk', {
        headers: {
          Authorization: 'Bearer ' + publicToken
        }
      })
      return response.data
    } catch (e) {
      Bugsnag.notify(e)
      throw e
    }
  },
  async getServiceToken (idService) {
    try {
      const response = await axios.get(url + '/get_service_token/' + idService)
      return response.data.token
    } catch (e) {
      Bugsnag.notify(e)
      throw e
    }
  },
  async getDemoToken (idService) {
    try {
      const response = await axios.get(url + '/get_demo_token/' + idService)
      return response.data.token
    } catch (e) {
      Bugsnag.notify(e)
      throw e
    }
  },
  async getAssistanceForm () {
    try {
      const response = await axios.get(url + '/global/get_assistance_form')
      return response.data
    } catch (e) {
      Bugsnag.notify(e)
      throw e
    }
  },
  sendAssistanceForm (data) {
    try {
      return axios.post(url + '/global/send_assistance_form', data)
    } catch (e) {
      Bugsnag.notify(e)
      throw e
    }
  },
  async updateNbDateConnexion (serviceToken) {
    try {
      await axios.get(url + '/update_nb_date_connection', {
        headers: {
          Authorization: 'Bearer ' + serviceToken
        }
      })
    } catch (e) {
      Bugsnag.notify(e)
      throw e
    }
  }
}
