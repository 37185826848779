import Bugsnag from '@bugsnag/js'

let axios
let url

export default {
  setAxios ($axios) {
    axios = $axios
  },
  setUrl (paramUrl) {
    url = paramUrl
  },
  async getFile (filename, token) {
    try {
      return await axios.get(url + '/secured/files/' + filename, {
        headers: {
          Authorization: 'Bearer ' + token
        },
        responseType: 'blob'
      })
    } catch (e) {
      Bugsnag.notify(e)
      throw e
    }
  }
}
