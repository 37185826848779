export const state = () => ({
  publicToken: null,
  services: null,
  activeSubscriptionsInfo: null,
  activeDemosInfo: null,
  currentService: null,
  servicesPageInitialized: false,
  serviceToConnect: {},
  loginPageMessage: null
})

export const getters = {
  publicToken: (state) => {
    return state.publicToken
  },
  activeServicesInfo: (state) => {
    const returnedServicesInfo = []
    if (state.activeSubscriptionsInfo !== null) {
      for (let i = 0; i < state.activeSubscriptionsInfo.length; i++) {
        const activeSubscriptionInfo = state.activeSubscriptionsInfo[i]
        activeSubscriptionInfo.isDemo = false
        returnedServicesInfo.push(activeSubscriptionInfo)
      }
    }
    if (state.activeDemosInfo !== null) {
      for (let i = 0; i < state.activeDemosInfo.length; i++) {
        const activeDemoInfo = state.activeDemosInfo[i]
        const subscription = returnedServicesInfo.find(element => element.id === activeDemoInfo.id)
        if (subscription === undefined) {
          activeDemoInfo.isDemo = true
          returnedServicesInfo.push(activeDemoInfo)
        }
      }
    }
    returnedServicesInfo.sort((a, b) => a.tri - b.tri)
    return returnedServicesInfo
  },
  firstSubscriptionEndDate: (state) => {
    let result = null
    if (state.activeSubscriptionsInfo !== null) {
      for (let i = 0; i < state.activeSubscriptionsInfo.length; i++) {
        const activeSubscriptionInfo = state.activeSubscriptionsInfo[i]
        const dateEnd = new Date(activeSubscriptionInfo.dateFin)
        if (result === null || dateEnd < result) {
          result = dateEnd
        }
      }
    }
    return result
  },
  currentService: (state) => {
    return state.currentService
  },
  services: (state) => {
    return state.services
  },
  categories: (state, getters) => {
    if (getters.activeServicesInfo === null) {
      return []
    }
    const returnedArray = []
    const categories = []
    for (let i = 0; i < getters.activeServicesInfo.length; i++) {
      const infoService = getters.activeServicesInfo[i]
      if (!categories.includes(infoService.categorie) && infoService.categorie !== null) {
        categories.push(infoService.categorie)
      }
    }
    const n = categories.length
    for (let i = 0; i < n; i++) {
      const categoryName = categories[i]
      const category = {
        id: i,
        title: categoryName,
        children: getters.activeServicesInfo.filter(service => service.categorie === categoryName)
      }
      returnedArray.push(category)
    }
    return returnedArray
  },
  serviceToConnect: (state) => {
    return state.serviceToConnect
  },
  gtmUserData: (state, getters, rootState, rootGetters) => {
    const user = rootState.auth.user
    let userData = {
      user_id: ''
    }
    if (user !== null) {
      userData = {
        user_id: user.id,
        firstname: user.prenom,
        lastname: user.nom,
        email: user.mail
      }
    }
    return userData
  },
  loginPageMessage: (state) => {
    return state.loginPageMessage
  }
}

export const actions = {
  async nuxtServerInit (store, context) {
    if (context.req.url === '/user?doubleConnexion=1') {
      context.$cookies.set('showDoubleAuthentificationMessage', true)
      await context.$auth.logout()
      context.redirect('/')
    }
    if (context.req.method === 'POST' && process.server) {
      const data = context.req.body
      if (data.token !== undefined) {
        await context.$auth.setUserToken(data.token)
        context.redirect('/')
      }
    }
    const services = require('~/data/services-' + context.$config.env + '.json')
    const publicToken = await this.$backOffice.getToken(context.$config.publicUsername, context.$config.publicPassword)
    this.$backOffice.setPublicToken(publicToken)
    const servicesInfo = await this.$backOffice.getServicesInfos()
    for (const [code, service] of Object.entries(services)) {
      const serviceInfo = servicesInfo.find(serviceInfoData => serviceInfoData.code === code)
      service.info = serviceInfo
    }
    store.commit('SET_SERVICES', services)
    store.commit('SET_PUBLIC_TOKEN', 'Bearer ' + publicToken)
  },
  async initData ({ commit, state }) {
    if (!state.servicesPageInitialized) {
      const activeSubscriptions = await this.$backOffice.getActiveSubscriptionsInfo()
      for (let i = 0; i < activeSubscriptions.length; i++) {
        activeSubscriptions[i].token = await this.$backOffice.getServiceToken(activeSubscriptions[i].id)
      }
      commit('SET_ACTIVE_SUBSCRIPTIONS_INFO', activeSubscriptions)
      const activeDemos = await this.$backOffice.getActiveDemosInfo()
      for (let i = 0; i < activeDemos.length; i++) {
        activeDemos[i].token = await this.$backOffice.getDemoToken(activeDemos[i].id)
      }
      commit('SET_ACTIVE_DEMOS_INFO', activeDemos)
      commit('SET_SERVICE_PAGE_INITIALIZED', true)
    }
  },
  clearData ({ commit }) {
    commit('SET_ACTIVE_SUBSCRIPTIONS_INFO', null)
    commit('SET_ACTIVE_DEMOS_INFO', null)
    commit('SET_SERVICE_PAGE_INITIALIZED', false)
    commit('SET_SERVICE_TO_CONNECT', {})
  },
  setCurrentService ({ commit }, service) {
    commit('SET_CURRENT_SERVICE', service)
  },
  setServiceToConnect ({ commit }, service) {
    commit('SET_SERVICE_TO_CONNECT', service)
  },
  clearCurrentService ({ commit }) {
    commit('SET_CURRENT_SERVICE', null)
  },
  setLoginPageMessage ({ commit }, messageObj) {
    commit('SET_LOGIN_PAGE_MESSAGE', messageObj)
  },
  clearLoginPageMessage ({ commit }) {
    commit('SET_LOGIN_PAGE_MESSAGE', null)
  }
}

export const mutations = {
  SET_PUBLIC_TOKEN (state, value) {
    state.publicToken = value
  },
  SET_SERVICES (state, value) {
    state.services = value
  },
  SET_ACTIVE_SUBSCRIPTIONS_INFO (state, value) {
    state.activeSubscriptionsInfo = value
  },
  SET_ACTIVE_DEMOS_INFO (state, value) {
    state.activeDemosInfo = value
  },
  SET_CURRENT_SERVICE (state, value) {
    state.currentService = value
  },
  SET_SERVICE_TO_CONNECT (state, value) {
    state.serviceToConnect = value
  },
  SET_SERVICE_PAGE_INITIALIZED (state, value) {
    state.servicesPageInitialized = value
  },
  SET_LOGIN_PAGE_MESSAGE (state, value) {
    state.loginPageMessage = value
  }
}
