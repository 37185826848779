export const NewsColumn = () => import('../../components/newsColumn.vue' /* webpackChunkName: "components/news-column" */).then(c => wrapFunctional(c.default || c))
export const NewsColumnBlock = () => import('../../components/newsColumnBlock.vue' /* webpackChunkName: "components/news-column-block" */).then(c => wrapFunctional(c.default || c))
export const PublicationColumn = () => import('../../components/publicationColumn.vue' /* webpackChunkName: "components/publication-column" */).then(c => wrapFunctional(c.default || c))
export const PublicationColumnBlock = () => import('../../components/publicationColumnBlock.vue' /* webpackChunkName: "components/publication-column-block" */).then(c => wrapFunctional(c.default || c))
export const ServicesTable = () => import('../../components/servicesTable.vue' /* webpackChunkName: "components/services-table" */).then(c => wrapFunctional(c.default || c))
export const ServicesTableItem = () => import('../../components/servicesTableItem.vue' /* webpackChunkName: "components/services-table-item" */).then(c => wrapFunctional(c.default || c))
export const FormPasswordForm = () => import('../../components/form/passwordForm.vue' /* webpackChunkName: "components/form-password-form" */).then(c => wrapFunctional(c.default || c))
export const UiEnerAssistance = () => import('../../components/ui/enerAssistance.vue' /* webpackChunkName: "components/ui-ener-assistance" */).then(c => wrapFunctional(c.default || c))
export const UiEnerLoader = () => import('../../components/ui/enerLoader.vue' /* webpackChunkName: "components/ui-ener-loader" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
