

export default {
  name: 'EnerAssistance',
  data () {
    return {
      formHtml: null,
      isFormVisible: false,
      isFormSent: false
    }
  },
  async mounted () {
    this.formHtml = await this.$backOffice.getAssistanceForm()
  },
  methods: {
    async handleClick (e) {
      if (e.target.className === 'assistance-form-buttons-close') {
        this.isFormVisible = false
      } else if (e.target.id === 'edit-submit') {
        e.preventDefault()
        const formElement = document.getElementById('assistance-form')
        const formData = new FormData(formElement)
        await this.$backOffice.sendAssistanceForm(formData)
        this.isFormSent = true
        this.isFormVisible = false
        this.hideFormMessage()
      }
    },
    hideFormMessage () {
      const scope = this
      setTimeout(() => { scope.isFormSent = false }, 3000)
    }
  }
}
