export default function ({ $gtm, app, store }) {
  app.router.afterEach((to) => {
    if (to.name !== null) {
      setTimeout(() => {
        const contentGroup = to.name.substring(0, to.name.indexOf('_'))
        $gtm.push({
          event: 'datalayer_ready',
          content_group: contentGroup,
          language: 'en',
          user_data: store.getters.gtmUserData
        })
      }, 250)
    }
  })
}
