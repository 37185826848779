import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _eb96992c = () => interopDefault(import('../pages/EnerTram.vue' /* webpackChunkName: "pages/EnerTram" */))
const _3b709465 = () => interopDefault(import('../pages/hydrogenDatabase.vue' /* webpackChunkName: "pages/hydrogenDatabase" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _457d2390 = () => interopDefault(import('../pages/set-password.vue' /* webpackChunkName: "pages/set-password" */))
const _af8b76b6 = () => interopDefault(import('../pages/update-password.vue' /* webpackChunkName: "pages/update-password" */))
const _48a88074 = () => interopDefault(import('../pages/lngDatabase.vue' /* webpackChunkName: "pages/lngDatabase" */))
const _0c3a1979 = () => interopDefault(import('../pages/refineryDatabase.vue' /* webpackChunkName: "pages/refineryDatabase" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7c6b8a72 = () => interopDefault(import('../pages/connect/_serviceCode.vue' /* webpackChunkName: "pages/connect/_serviceCode" */))
const _40e8f248 = () => interopDefault(import('../pages/change-password/_contactId/_token.vue' /* webpackChunkName: "pages/change-password/_contactId/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/enertram-service.html",
    component: _eb96992c,
    name: "EnerTram___en"
  }, {
    path: "/global-hydrogen-companies-database-service.html",
    component: _3b709465,
    name: "hydrogenDatabase___en"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login___en"
  }, {
    path: "/set-password.html",
    component: _457d2390,
    name: "set-password___en"
  }, {
    path: "/update-password.html",
    component: _af8b76b6,
    name: "update-password___en"
  }, {
    path: "/world-lng-database-service.html",
    component: _48a88074,
    name: "lngDatabase___en"
  }, {
    path: "/world-refinery-database-service.html",
    component: _0c3a1979,
    name: "refineryDatabase___en"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/connect/:serviceCode?",
    component: _7c6b8a72,
    name: "connect-serviceCode___en"
  }, {
    path: "/change-password/:contactId?/:token?",
    component: _40e8f248,
    name: "change-password-contactId-token___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
