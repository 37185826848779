export default function ({ route, redirect, $auth }) {
  if (route.name !== null && !route.name.startsWith('update-password') && $auth.loggedIn === true && !$auth.user.mail.endsWith('@enerdata.net')) {
    const passwordUpdateDate = new Date($auth.user.passwordLastUpdate)
    const maxPasswordDate = new Date()
    maxPasswordDate.setDate(maxPasswordDate.getDate() - 26 * 7)
    if (passwordUpdateDate < maxPasswordDate) {
      redirect('/update-password.html')
    }
  }
}
