import qs from 'qs'
import globalServices from '@/api/globalServices'
import backOffice from '@/api/backOffice'

export default function (ctx, inject) {
  if (!ctx.store) {
    console.error('Please make sure store module is added')
    return
  }
  if (!ctx.$axios) {
    console.error('Please make sure $axios module is added')
    return
  }
  if (!ctx.$config) {
    console.error('Please make sure $config module is added')
    return
  }
  ctx.$axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params, { arrayFormat: 'indices', allowDots: true })
  }
  ctx.$axios.interceptors.request.use((request) => {
    if (!request.headers.Authorization) {
      let token = null
      if (ctx.$auth.loggedIn) {
        token = ctx.$auth.strategy.token.get()
      } else if (ctx.store.getters.publicToken !== null) {
        token = ctx.store.getters.publicToken
      }
      if (token !== null) {
        request.headers.Authorization = token
      }
    }
    return request
  })
  // ctx.$axios.interceptors.response.use((response) => {
  //   console.log('Response:', response.data)
  //   return response
  // })
  backOffice.setAxios(ctx.$axios)
  backOffice.setUrl(ctx.$config.backOfficeApiUrl)
  inject('backOffice', backOffice)
  globalServices.setAxios(ctx.$axios)
  globalServices.setUrl(ctx.$config.globalServicesApiUrl)
  inject('globalServices', globalServices)
}
