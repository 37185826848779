
import { mapActions } from 'vuex'

export default {
  computed: {
    crumbs () {
      const availableRouteName = [
        'EnerTram',
        'hydrogenDatabase',
        'lngDatabase',
        'refineryDatabase'
      ]
      const serviceNameForRoute = {
        EnerTram: 'EnerTram',
        hydrogenDatabase: 'Global Hydrogen Companies Database',
        lngDatabase: 'World LNG Database ',
        refineryDatabase: 'World Refinery Database'
      }
      if (this.$route.name === null) {
        return []
      }
      const simplifiedRouteName = this.$route.name.substring(0, this.$route.name.indexOf('_'))
      if (availableRouteName.includes(simplifiedRouteName)) {
        return [
          {
            text: 'Energy Research Services',
            to: '/'
          },
          {
            text: serviceNameForRoute[simplifiedRouteName],
            active: true
          }
        ]
      }
      return []
    }
  },
  methods: {
    ...mapActions({
      clearData: 'clearData'
    }),
    async handleLogoutClick () {
      await this.$auth.logout()
      this.clearData()
    }
  }
}
