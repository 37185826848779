import Vue from 'vue'

export default ({ app }) => {
  Vue.filter('formatDate', (value, format) => {
    const date = new Date(value)
    switch (format) {
      case 'DD':
        return date.toLocaleString(['en-GB'], { day: '2-digit' })
      case 'MMM':
        return date.toLocaleString(['en-GB'], { month: 'short' })
      case '%d %B %Y':
      case 'DD MMMM Y':
        return date.toLocaleString(['en-GB'], { day: '2-digit', month: 'long', year: 'numeric' })
      case '%B %Y':
        return date.toLocaleString(['en-GB'], { month: 'long', year: 'numeric' })
      default:
        return date.toLocaleString(['en-GB'], {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
    }
  })
}
